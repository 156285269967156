.field-name {
  color: grey;
}

.cursor {
  cursor: pointer;
}

.add-card-details input {
  background: rgb(23, 23, 23) !important;
}

.add-card-details textarea {
  background-color: #383838 !important;
  width: 100% !important;
  border: none !important;
}

.css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6.5px 14px !important;
  background-color: #1f1f1f;
}

.select {
  background-color: #181818 !important;
  width: 150% !important;
  color: #181818 !important;
  border: none !important;
}

.process-completed {
  color: #6ed49d;
}

.process-complete-text {
  font-weight: 700;
  font-weight: bold;
}

.process-failed {
  color: #e55c5c;
}

.danger {
  background-color: #e55c5c !important;
  width: 100% !important;
  color: #fff !important;
  border: none !important;
}

.process-failed button {
  background-color: #e02d2d !important;
  color: #fff !important;
  font-weight: 700;
  width: 100% !important;
}

.add-card-details .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.add-card-details .MuiDialog-paper {
  background: rgb(23, 23, 23) !important;
  border-radius: 1rem !important;
}

.month-field {
  width: 80px;
  padding-right: 0;
}

.css-if98jw-MuiPaper-root-MuiDialog-paper {
  background: rgb(23, 23, 23) !important;
  border-radius: 14px !important;
}

.popup-action-container {
  min-height: 100px;
}

.action-container {
  height: 105px !important;
}
