.cart-image {
  width: 90%;
}

.product-title {
  font-size: 1.4rem;
  color: white;
}

.product-sub-title {
  font-size: 1rem;
  color: #ffffff;
  line-height: 0.5;
}

.product-price-text {
  font-size: 1rem;
  color: #ffffff;
  line-height: 0.7;
  flex-grow: 1;
  text-align: right;
}

.product-price {
  font-size: 1.8rem;
  font-weight: 600;
  color: white;
  padding-top: 10px;
}

.product-discount-price-text {
  font-size: 0.9rem;
  color: #dbdbdb;
  line-height: 1.5;
  flex-grow: 1;
  text-align: right;
}

.product-discount-price {
  font-size: 1.2rem;
  color: white;
  padding-top: 10px;
}

.badge-red {
  color: rgba(229, 92, 92, 1);
  border: 1px solid rgba(229, 92, 92, 1);
  border-radius: 3px;
  display: inline;
  padding: 4px;
  font-size: 12px;
}

.background-light-grey {
  background: #3d3d3d !important;
  padding: 1rem;
}

.total-text {
  font-size: 0.875rem;
  line-height: 1;
}

.total-int {
  font-size: 1rem;
}

.total-price {
  font-size: 1.125rem !important;
}

.checkout-product-grid .MuiGrid-item {
  padding-top: 0px !important;
}

/* To remove default styling */
.checkout-textarea {
  background-color: #1f1f1f !important;
  width: 100% !important;
  color: rgb(255, 255, 255) !important;
  height: 10rem !important;
}

.checkout textarea {
  padding: 10px 14px !important;
  width: 100% !important;
  border-radius: 8px !important;
}

.input-checkout-grey {
  padding: 6px;
  background: #3d3d3d;
  border: 1px solid white;
  border-radius: 8px;
}

.checkout-select {
  background: transparent;
  color: white;
  border: none;
  padding-left: 0px;
}

.checkout-product-grid .MuiGrid-item {
  padding-top: 0px !important;
}

/* To remove default styling */

.checkout-select-container .MuiOutlinedInput-notchedOutline {
  border: none !important;
  height: 1rem !important;
}

.checkout-select-container .MuiSelect-select {
  padding-left: 0;
}
.checkout-btn {
  text-align: right;
}
@media only screen and (max-width: 480px) {
  .product-price {
    font-size: 1.5rem;
  }
  .checkout-btn {
    text-align: left;
  }
}

.model-list li {
  opacity: 80%;
  font-size: 14px;
}

.dailog-action button {
  background-color: #e02d2d !important;
  color: #fff !important;
  font-weight: 700;
  width: 100% !important;
}
/* Bill payment Popup */
.billPayment-popup {
  width: 800px;
}
.Popup-Title {
  font-size: 18px;
  font-weight: 700;
  opacity: 0.8;
}
.popup-subHeader {
  font-size: 16px;
  font-weight: 700;
  opacity: 0.8;
}
.popup-content {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.8;
}
@media only screen and (max-width: 600px) {
  .responsive-checkout {
    width: 100%;
    height: 65px;
    border-radius: 8px;
  }
  .img.responsive-checkoutd-block.cursor {
    width: 100%;
    height: 65px;
    border-radius: 8px;
  }
}
@media only screen and (min-width: 900px) {
  .responsive-checkout {
    width: 100%;
    height: 168px;
    border-radius: 8px;
  }
}
.checkout-textarea:focus {
  outline: none !important;
  border: 1px solid rgba(230, 92, 92, 0.8);
}

.no-underline{
  text-decoration: none;
}