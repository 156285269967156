.cart-image {
  width: 90%;
}

.product-title {
  font-size: 1.4rem;
  color: white;
}

.product-sub-title {
  font-size: 1rem;
  color: #ffffff;
  line-height: 0.5;
}

.product-price-text {
  font-size: 1rem;
  color: #ffffff;
  line-height: 0.7;
  flex-grow: 1;
  text-align: right;
}

.product-price-cart {
  font-size: 1.8rem;
  font-weight: 600;
  color: white;
  padding-top: 10px;
}

.product-discount-price-text {
  font-size: 0.9rem;
  color: #dbdbdb;
  line-height: 1.5;
  flex-grow: 1;
  text-align: right;
}

.product-discount-price {
  font-size: 1.2rem;
  color: white;
  padding-top: 10px;
}

.badge-red {
  color: rgba(229, 92, 92, 1);
  border: 1px solid rgba(229, 92, 92, 1);
  border-radius: 4px;
  display: inline;
  padding: 4px;
  font-size: 12px;
  font-weight: 700;
}

.total-text {
  font-size: 0.875rem;
  line-height: 1;
}

.total-int {
  font-size: 1rem;
}

.total-price {
  font-size: 1.125rem !important;
}

.custom-popup .MuiDialog-container .MuiDialog-paper {
  padding: 1rem;
  background: rgba(31, 31, 31, 0.5) !important;
  backdrop-filter: blur(40.7742px) !important;
  border-radius: 1rem !important;
}
.shipping-charge-popup .MuiDialog-container .MuiDialog-paper {
  width: 800px;
}
.custom-alert .MuiDialog-container .MuiDialog-paper {
  background: rgba(31, 31, 31, 0.5) !important;
  backdrop-filter: blur(10px) !important;
  border-radius: 1rem !important;
}

.custom-border {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.cart-price-margin {
  margin-top: 18px;
  margin-bottom: 18px;
}
