.model-Title {
  color: #ffffff;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 16px;
}
.model-content {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.model-footer-border {
  border-top: 1px solid rgba(84, 84, 88, 0.65);
}
.model-button {
  font-size: 14px;
  font-weight: 400;
  font-family: "Noto Sans";
  font-style: normal;
  color: #e65c5c;
  background: rgba(31, 31, 31, 0.75);
  padding: 10px 0;
  cursor: pointer;
}

.dailog-popup-button {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: rgba(229, 92, 92, 1);
  background: transparent;
  border: 0;
}
.cancel-popup-btn {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #ffff;
  background: transparent;
  border: 0;
}
.button-container {
  padding: 8px 12px;
}
@media only screen and (min-width: 600px) {
  .model-background {
    background: rgba(31, 31, 31, 0.75);
    width: 350px;
  }
}
@media only screen and (min-width: 992px) {
  .model-background {
    background: rgba(31, 31, 31, 0.75);
    width: 400px;
  }
}
@media only screen and (max-width: 600px) {
  .model-background {
    background: rgba(31, 31, 31, 0.75);
    width: 250px;
  }
}
