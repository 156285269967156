/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Dialog_Txt_Field input {
    font-size: 1rem;
  }

  .List_Items_Grid img {
    width: 2.625rem !important;
    height: 1.813rem !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .Dialog_Txt_Field input {
    font-size: 1rem;
  }

  .List_Items_Grid {
    margin-right: 1rem;
  }

  .List_Items_Grid img {
    width: 2.625rem !important;
    height: 1.813rem !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .Dialog_Txt_Field input {
    font-size: 1rem;
  }

  .List_Items_Grid {
    margin-right: 1rem;
  }

  .List_Items_Grid img {
    width: 2.625rem !important;
    height: 1.813rem !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .Dialog_Txt_Field input {
    font-size: 1rem;
  }

  .List_Items_Grid {
    margin-right: 1rem;
  }

  .List_Items_Grid img {
    width: 2.625rem !important;
    height: 1.813rem !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .Dialog_Txt_Field input {
    font-size: 0.875rem;
  }

  .List_Items_Grid {
    margin-right: 1rem;
  }

  .List_Items_Grid img {
    width: 2.625rem !important;
    height: 1.813rem !important;
  }
}

.add-card-details .Dialog_Box {
  padding: 0.5rem !important;
}

.add-card-details h2 {
  padding-bottom: 0px;
}

.add-card-details .Dailog_Context_Box {
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin: -9px;
}

.add-card-details .Grid_Box {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
